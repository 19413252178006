<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A fictional base,
        <chemical-latex content="B," />
        has a
        <stemble-latex :content="'$\\text{K}_{\\text{b}}$'" />
        value of
        <number-value :value="Kb" />. Answer each of the questions below assuming you start with a
        <number-value :value="conc" />
        <stemble-latex :content="'$\\text{M}$'" />
        solution of
        <chemical-latex content="B\text{.}" />
      </p>

      <p class="mb-3">
        a) Based on the information provided above, fill in the ICE table below inputting
        <stemble-latex content="$\mathrm{x}$" />
        for any relative changes. Be sure to indicate the direction of change, i.e. +/-. Be sure to
        use the proper number of significant figures. Any zeroes should be denoted as 0. The
        equilibrium row should contain the
        <stemble-latex content="$\mathrm{x}$" /> variable.
      </p>

      <v-simple-table class="mb-8" dense>
        <thead class="text-center">
          <tr>
            <th style="vertical-align: middle; text-align: center; font-size: 15px" />
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="B(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="H2O(l)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="<=>" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="BH^+(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <chemical-latex content="OH^-(aq)" />
            </th>
          </tr>

          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$\textbf{I}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialB" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialBH" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialOH" group-size="sm" class="text-center" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$\textbf{C}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeB" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeBH" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeOH" class="text-center" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$\textbf{E}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumB" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumBH" class="text-center" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumOH" class="text-center" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-3">
        b) Determine
        <chemical-latex content="[OH-]" />
        once the reaction reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.valueOfx"
        class="mb-6"
        :prepend-text="'$\\ce{[OH^-]:}$'"
        :append-text="'$\\mathrm{M}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) Determine the
        <stemble-latex content="$\textbf{pH}$" />
        of the system when it reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.pH"
        class="mb-6"
        :prepend-text="'$\\mathrm{pH:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-3">d) Determine the percent ionization of the weak base.</p>

      <calculation-input
        v-model="inputs.percentIonization"
        class="mb-6"
        :prepend-text="'$\\text{Percent Ionization:}$'"
        :append-text="'$\\%$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import IceInput from '../inputs/IceInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question306',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    IceInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        initialB: null,
        initialOH: null,
        initialBH: null,
        changeB: null,
        changeOH: null,
        changeBH: null,
        equilibriumB: null,
        equilibriumOH: null,
        equilibriumBH: null,
        valueOfx: null,
        pH: null,
        percentIonization: null,
      },
    };
  },
  computed: {
    conc() {
      return this.taskState.getValueBySymbol('conc').content;
    },
    Kb() {
      return this.taskState.getValueBySymbol('Kb').content;
    },
  },
};
</script>
